import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { graphql, useStaticQuery } from "gatsby"
import { Container } from "../global"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      text: file(name: { eq: "wypozycz" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      icon: file(name: { eq: "icon" }) {
        childImageSharp {
          fluid(maxWidth: 100, maxHeight: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper id="footer">
        <ImgWrapper>
          <Icon fluid={data.icon.childImageSharp.fluid} />
          <Logo fluid={data.text.childImageSharp.fluid} />
        </ImgWrapper>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 30px 0 0;
  padding: 15px 0 30px;
`

const Icon = styled(Img)`  
  position: absolute !important;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  width: 25px;
`

const Logo = styled(Img)`  
  position: relative;
  border-top: 1.2px solid ${props => props.theme.color.blue.regular};
`

const ImgWrapper = styled(Container)`
  position: relative;
  display: block;
  width: 200px;
`

export default Footer
