import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { graphql, useStaticQuery } from "gatsby"
import { Section, Container } from "../global"

const Features = () => {
  const allImages = useStaticQuery(graphql`
    query {
        allImageSharp {
          edges {
            node {
              id
              fluid(maxHeight: 200, maxWidth: 200) {
                src
                srcSet
                base64
                aspectRatio
                originalImg
                sizes
                originalName
              }
            }
          }
        }
    }
  `).allImageSharp.edges

  const getImageFluidByName = (imageName) => {
    var image = allImages.filter(n => n.node.fluid.originalName === imageName)[0];

    return image.node.fluid;
  }

  return (
    <Section id="learn-more">
      <StyledContainer>
        <FeatureRow reverse>
          <FeatureImage>
            <StyledImage fluid={getImageFluidByName("all-devices.jpeg")} />
          </FeatureImage>
          <FeatureContent>
            <FeatureTitle>Używaj na każdym urządzeniu</FeatureTitle>
            <FeatureText>Główną cechą systemu jest uniwersalne podejście do urządzenia, na którym zostanie wyświetlona aplikacja. Zawsze w pełni funkcjonalna, niezależnie czy używasz telefonu, tabletu czy komputera.</FeatureText>
          </FeatureContent>
        </FeatureRow>
        <FeatureRow>
          <FeatureImage>
            <StyledImage fluid={getImageFluidByName("clients-network.jpeg")} />
          </FeatureImage>
          <FeatureContent>
            <FeatureTitle>Sieć klientów</FeatureTitle>
            <FeatureText>Twórz sieć klientów, by informować ich o nadchodzących promocjach lub zwalniających się terminach.</FeatureText>
          </FeatureContent>
        </FeatureRow>
        <FeatureRow reverse>
          <FeatureImage>
            <StyledImage fluid={getImageFluidByName("schedule.jpeg")} />
          </FeatureImage>
          <FeatureContent>
            <FeatureTitle>Kalendarz</FeatureTitle>
            <FeatureText>Koniec z zapisywaniem terminow w tradycyjnym kalendarzu! Przejrzysty interfejs kalendarza online ułatwi sprawę.</FeatureText>
          </FeatureContent>
        </FeatureRow>
      </StyledContainer>
    </Section>
  )
}

export default Features

const StyledContainer = styled(Container)`
  padding: 0 140px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 0;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 0;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 0 30px;
  }
`

const FeatureRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  color: #4c5267;
  
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 0 10px 30px 10px;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: grid;
    margin-bottom: 50px;
  }

  ${props => props.reverse &&`
    flex-direction: row-reverse;    
  `}
`
const FeatureContent = styled.div`
  flex: 0 0 60%;
  text-align: left;
  font-stretch: expanded;
  padding: 0 8px;
  margin: auto 0;

  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 0;
  }

  ${props => props.reverse &&`
    background:red;    
  `}
`

const FeatureTitle = styled.h3`
  margin: 0 0 15px 0;

  @media (max-width: ${props => props.theme.screen.xs}) {
    margin: 15px 0;
    font-size: 24px;
  }
`
const FeatureText = styled.div``

const FeatureImage = styled.div`
  flex: 0 0 40%;
  text-align: center;
  padding: 0 8px;
`

const StyledImage = styled(Img)`
  width: 100%;
  border-radius: 4px;

  @media (max-width: ${props => props.theme.screen.md}) {
    
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    
  }
`

