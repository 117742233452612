import React from "react"

import Layout from "../components/common/layout/layout"
import Head from "../components/sections/head"
import SEO from "../components/common/layout/seo"
import CHAT from "../components/common/layout/chat"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import LearnMore from "../components/sections/learnMore"
import Footer from "../components/sections/footer"
import Signup from "../components/sections/signup"

const IndexPage = () => (
  <Layout>
    <Head />
    <SEO title="Twoja wypożyczalnia w chmurze!" />
    <Navigation />
    <Header />
    <LearnMore />
    <Signup />
    <CHAT/>
    <Footer />
  </Layout>
)

export default IndexPage
