import React from "react"
import { Helmet } from "react-helmet";

const Head = () => (
  <Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-XGP4576NPQ"></script>
    <script>
      {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());    
          gtag('config', 'G-XGP4576NPQ');
        `}
    </script>
  </Helmet>
)

export default Head