import React from "react"
import Helmet from "react-helmet"

const CHAT = () => (
    <>
        <Helmet>
            <script>{
                `window.fbAsyncInit = function() {
                    FB.init({
                        xfbml: true,
                        version: 'v10.0'
                    });
                };

                (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));`}
            </script>
        </Helmet>
        <div id="fb-root"></div>        
        <div class="fb-customerchat"
            attribution="setup_tool"
            page_id="100546692156460">
        </div>
    </>
)

export default CHAT
