import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "wypozycz_icon" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <ImgWrapper>
      <Img fluid={data.file.childImageSharp.fluid} />
    </ImgWrapper>
  )
}

export default Logo

const ImgWrapper = styled.div`
  width: 320px;
  padding-left: 2px;
`