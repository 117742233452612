import React, { Component } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import { Menu, X } from "react-feather"
import Logo from "../navigation/logo"
import styled from "styled-components"

import { Container } from "../../global"
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from "./style"

const NAV_ITEMS = [
  {
    label: "Start",
    anchor: "#start",
    blue: false
  },
  {
    label: "Dowiedz się więcej",
    anchor: "#learn-more",
    blue: false
  },
  {
    label: "Zdobądź darmowe zaproszenie",
    anchor: "#signup",
    blue: true
  }];

export default class Navigation extends Component {
  state = {
    mobileMenuOpen: false,
    hasScrolled: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getNavAnchorLink = item => ( item.blue
    ? (<BlueLink href={item.anchor} onClick={this.closeMobileMenu}>{item.label}</BlueLink>)
    : (<RegularLink href={item.anchor} onClick={this.closeMobileMenu}>{item.label}</RegularLink>)    
  )

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.anchor)}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (<NavItem key={navItem.anchor}>{this.getNavAnchorLink(navItem)}</NavItem>))}
      </Scrollspy>
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <AnchorLink href="#start" onClick={this.closeMobileMenu}>
                <Logo />
              </AnchorLink>
            </Scrollspy>
          </Brand>
          <Mobile>
            <button
              onClick={this.toggleMobileMenu}
              style={{ color: "#0072ff", background: "none" }}
            >
              {this.state.mobileMenuOpen ? (
                <X size={24} alt="close menu" />
              ) : (
                <Menu size={24} alt="open menu" />
              )}
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    )
  }
}

const RegularLink = styled(AnchorLink)`
  display: block;
  padding: 10px 0;
  color: ${props => props.theme.color.blue.regular} !important;
`

const BlueLink = styled(AnchorLink)`
  font-weight: 500;
  font-size: 14px;
  color: white !important;
  letter-spacing: 1px;
  height: 40px;
  width: 100%;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.blue.regular};
  border-radius: 4px;
  padding: 10px 20px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`