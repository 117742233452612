import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { Container } from "../global"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "start-image" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="start">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              Wypożycz online!
            </h1>
            <h2>
              Nowoczesna i prosta aplikacja dla wypożyczalni. Nieistotne co wypożyczasz! W tej aplikacji bez problemu zapanujesz nad terminami.
            </h2>
            <Link href="#learn-more">
              <Button>Dowiedz się więcej</Button>
            </Link>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background: linear-gradient(90deg,#0072ff,#00c6ff);
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`
const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    font-family: ${props => props.theme.font.light};
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 24px;
    color: ${props => props.theme.color.primary};
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const Link = styled(AnchorLink)`
  text-decoration: none;
`

const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.blue.regular};
  letter-spacing: 1px;
  height: 60px;
  width: 100%;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    margin-left: 30px;
  }
`

const StyledImage = styled(Img)`
  width: 500px;
  border-radius: 4px;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
